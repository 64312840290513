import axios from "axios";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/sync";

export class SyncService {
  public static async syncRefurbishedProducts() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(API_URL + "/startRefurbishedProducts", undefined, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async syncOrders() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(API_URL + "/startOrders", undefined, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async getLastRefurbishedProductSync() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/lastRefurbishedProductSync", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async getLastOrderSync() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/lastOrderSync", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }
}
