import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { Admin } from "./components/Admin";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import { Login } from "./components/Login";
import { Print } from "./components/Print";
import { Shipping } from "./components/Shipping";
import { Testing } from "./components/Testing";
import { SendToday } from "./components/order/SendToday";
import { Product } from "./components/product/Product";
import { ProductIssue } from "./components/product/ProductIssue";
import { ProductRegister } from "./components/product/ProductRegister";
import { ProductSearch } from "./components/product/ProductSearch";
import { Scanning } from "./components/product/Scanning";
import { UserAuditing } from "./components/product/UserAuditing";
import "./custom.css";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/printing" element={<Print />} />
          <Route path="/login" element={<Login />} />
          <Route path="/testing" element={<Testing />} />
          <Route path="/sendtoday" element={<SendToday />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/productsearch" element={<ProductSearch />} />
          <Route path="/productregister" element={<ProductRegister />} />
          <Route path="/productissue" element={<ProductIssue />} />
          <Route path="/userauditing" element={<UserAuditing />} />
          <Route path="/scanning" element={<Scanning />} />
          <Route path="/product/:id" element={<Product />} />
        </Routes>
      </Layout>
    );
  }
}
